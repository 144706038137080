<template>
  <section class="contact-us-comp" :class="{ container: container }">
    <div v-if="blok.description.content.length > 1" class="left">
      <div
        class="description"
        v-html="$storyapi.richTextResolver.render(blok.description)"
      ></div>
    </div>
    <transition name="sf-fade" mode="in-out">
      <div class="right" v-if="showSendForm">
        <h2 class="title">{{ $t("Contact") }}</h2>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form>
            <div class="form">
              <ValidationProvider
                name="form.company"
                rules="required"
                v-slot="{ errors }"
              >
                <div class="input-block">
                  <div class="input-wrapper">
                    <label
                      class="input-label"
                      for="company-input"
                      v-if="form.company.length < 1"
                      >{{ $t("Company name")
                      }}<span class="asterisk"> *</span></label
                    >
                    <input
                      id="company-input"
                      type="text"
                      v-model="form.company"
                    />
                  </div>
                  <span class="err-msg">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              <ValidationProvider
                name="form.name"
                rules="required"
                v-slot="{ errors }"
              >
                <div class="input-block">
                  <div class="input-wrapper">
                    <label
                      class="input-label"
                      for="name-input"
                      v-if="form.name.length < 1"
                      >{{ $t("Your name")
                      }}<span class="asterisk"> *</span></label
                    >
                    <input id="name-input" type="text" v-model="form.name" />
                  </div>
                  <span class="err-msg">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              <ValidationProvider
                name="form.type"
                rules="required"
                v-slot="{ errors }"
              >
                <div class="select-wrapper">
                  <div
                    id="select-wrap"
                    class="select-input"
                    :class="{ 'select-input-open': showDropdown }"
                    @click="showDropdown = !showDropdown"
                  >
                    <label
                      class="input-label"
                      for="input-select"
                      v-if="form.type.length < 1 && !showDropdown"
                      >{{ $t("Company type")
                      }}<span class="asterisk"> *</span></label
                    >
                    <input
                      id="input-select"
                      type="text"
                      disabled
                      v-model="form.type"
                      required
                    />
                    <Icon
                      icon="mdi:chevron-down"
                      :class="{ 'rotate-icon': showDropdown }"
                    />
                  </div>
                  <span class="err-msg">{{ errors[0] }}</span>
                  <transition name="dropdown">
                    <div class="dropdown" v-if="showDropdown">
                      <div
                        class="company"
                        v-for="(comp, index) in company_types"
                        :key="index + 'cmp'"
                        @click="
                          form.type = comp.type_value;
                          showDropdown = false;
                        "
                      >
                        <span>{{ comp.type_value }}</span>
                      </div>
                    </div>
                  </transition>
                </div>
              </ValidationProvider>
              <ValidationProvider
                name="form.email"
                rules="required|email"
                v-slot="{ errors }"
              >
                <div class="input-block">
                  <div class="input-wrapper">
                    <label
                      class="input-label"
                      for="email-input"
                      v-if="form.email.length < 1"
                      >{{ $t("Email address")
                      }}<span class="asterisk"> *</span></label
                    >
                    <input id="email-input" type="email" v-model="form.email" />
                  </div>
                  <span class="err-msg">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              <ValidationProvider
                name="form.message"
                rules="required"
                v-slot="{ errors }"
              >
                <div class="input-block">
                  <div class="textarea-wrapper">
                    <textarea
                      cols="30"
                      rows="10"
                      v-model="form.message"
                    ></textarea>
                  </div>
                  <span class="err-msg">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              <button
                class="btn"
                @click.prevent="handleSubmit().then(sendForm())"
              >
                {{ $t("Send this form") }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <div class="thank-you" v-else>
        <h2 class="font-mont">{{ $t("Thank you for contacting us") }}</h2>
      </div>
    </transition>
  </section>
</template>

<script>
import useCompanyType from "~/composables/useCompanyType";
import { useAsync, ref, useContext } from "@nuxtjs/composition-api";
import { Icon } from "@iconify/vue2";
import { useUiNotification } from "~/composables";
import { required, email } from "vee-validate/dist/rules";
import useContactUs from "~/composables/useContactUs";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";

extend("required", {
  ...required,
  message: "This field is required",
});
export default {
  name: "ContactUsForm",
  props: {
    blok: {
      type: Object,
      required: true,
      default: {},
    },
    container: {
      type: Boolean,
      required: false,
      default: false,
    },
    formNameInfo: {
      type: String,
      required: false,
      default: "Contact Us",
    },
  },
  components: {
    Icon,
    ValidationProvider,
    ValidationObserver,
  },

  setup(props) {
    const { app } = useContext();
    const { getData: getCompanyTypes } = useCompanyType();
    const company_types = ref([]);
    const showDropdown = ref(false);
    const { send: sendNotification } = useUiNotification();
    const form = ref({
      formname: props.formNameInfo,
      company: "",
      name: "",
      type: "",
      email: "",
      message: "",
    });
    const { contactUs } = useContactUs();
    const showSendForm = ref(true);
    const sendForm = async () => {
      const not_valid = Object.values(form.value).some((o) => o.length < 1);
      if (!not_valid) {
        await contactUs(form.value).then((res) => {
          if (res.data.customerSupportPage.message === "Success") {
            showSendForm.value = false;
          }
        });
      }
    };

    useAsync(async () => {
      await getCompanyTypes().then((res) => {
        company_types.value = res;
      });
    });

    return {
      company_types,
      showDropdown,
      form,
      sendForm,
      showSendForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.contact-us-comp {
  @apply flex;
  padding: 187px 25px 119px 25px;
  gap: 80px;
  @media only screen and (max-width: 768px) {
    gap: 10px;
    padding: 100px 0 0 0;
  }
  .left,
  .right {
    flex: 1;
  }

  .left {
    padding: 82px 0px 0 0px;
    .description {
      ::v-deep p {
        @apply mb-6 font-fgb;
        &:first-child {
          @apply mb-16;
        }
      }
    }
  }
  .right {
    @media only screen and (max-width: 768px) {
      @apply bg-grey;
      padding-bottom: 30px;
    }
    @apply font-fgb;
    .title {
      @apply text-red-title;
      font: normal normal normal 35px/43px Montserrat;
      @media only screen and (max-width: 768px) {
        @apply text-brownish-grey;
        font: normal normal bold 24px/32px Montserrat;
        margin-top: 20px;
      }
    }
    .form {
      @apply flex flex-col md:gap-8 gap-4;
      @media only screen and (max-width: 768px) {
        margin-top: 30px;
        gap: 2rem;
      }
      margin-top: 43px;
      .input-wrapper {
        @apply border border-solid rounded-3xl px-8 py-2 relative;
        @media only screen and (max-width: 768px) {
          background-color: white;
        }

        .input-label {
          @apply absolute px-8 font-fgb;
          left: 0;
          .asterisk {
            @apply text-fire-red;
          }
        }
        input {
          width: 100%;

          &::placeholder {
            color: #0f1717;
            @apply font-fgb;
          }
        }
      }
      .select-wrapper {
        @apply relative;
        #select-wrap {
          position: relative;
          z-index: 10;
          input#input-select {
            position: relative;
            z-index: -1;
            @media only screen and (max-width: 768px) {
              background-color: transparent;
            }
          }
        }
        .select-input {
          @apply border border-solid rounded-3xl px-8 py-2 cursor-pointer relative;
          @media only screen and (max-width: 768px) {
            background-color: white;
          }

          .input-label {
            @apply absolute px-8;
            left: 0;
            .asterisk {
              @apply text-fire-red;
            }
          }
          input {
            @apply cursor-pointer;
            width: 100%;
            background: white;

            &::placeholder {
              color: #0f1717;
            }
          }
        }

        .select-input-open {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          transition: all 200ms ease-in;
          border-bottom: none;
        }

        .iconify {
          @apply text-red-title text-4xl absolute;
          right: 10px;
          top: 3px;
          transition: all 100ms ease-in;
        }
        .rotate-icon {
          transform: rotate(180deg);
        }
        .dropdown {
          @apply absolute border border-solid rounded-3xl px-8 py-2;
          border-top: 1px solid #ccc;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          width: 100%;
          background: white;
          z-index: 2;
          left: 0;
          top: 40px;
          .company {
            @apply mb-2;
            &:hover {
              @apply font-bold cursor-pointer;
            }
          }
        }
      }
      .textarea-wrapper {
        @apply px-6 py-4;
        background: #efefef 0% 0% no-repeat padding-box;
        border-radius: 20px;
        @media only screen and (max-width: 768px) {
          background: #ffffff;
        }
        textarea {
          background: #efefef;
          @media only screen and (max-width: 768px) {
            background: #ffffff;
          }
          width: 100%;
          &:focus-visible {
            outline: none;
          }
        }
      }
      .btn {
        max-width: 250px;
        @media only screen and (max-width: 768px) {
          max-width: 150px;
          float: right;
          padding: 0.6rem;
          font-size: 12px;
          align-self: end;
        }
      }
    }
  }
  .thank-you {
    @apply flex justify-center items-center text-2xl text-fire-red;
    flex: 1;
  }
  @media only screen and (max-width: 1024px) {
    @apply flex-col pt-16;
    .left {
      @apply py-0 px-14;
    }
    .right {
      @apply px-14;
    }
  }
}
.err-msg {
  @apply absolute;
  padding-left: 1rem;
  font-size: 11px;
  color: #da3838;
}
</style>
